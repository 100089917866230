<template>
  <table class="float-right table-striped w-100">
    <tbody>
      <tr class="text-right">
        <td class="text-right font-weight-bold border-0 h6 p-3">Ürün Toplam :</td>
        <td class="text-right font-weight-bold border-0 pr-1 h6" width="40.5%">
          {{ form.rezervasyon_toplam.urun_toplam | cur }} {{ form.sabit_kur }}
        </td>
      </tr>
      <tr class="text-right" v-if="form.rezervasyon_toplam.ekstra_toplam > 0">
        <td class="text-right font-weight-bold h6 border-0 p-3">Ekstra Toplam :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">{{ form.rezervasyon_toplam.ekstra_toplam | cur }} {{ form.sabit_kur }}</td>
      </tr>
      <tr class="text-right text-danger" v-if="form.rezervasyon_toplam.kupon_indirim > 0">
        <td class="text-right font-weight-bold h6 border-0 p-3">İndirim Toplam :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">{{ form.rezervasyon_toplam.kupon_indirim | cur }} {{ form.sabit_kur }}</td>
      </tr>
      <tr class="text-right text-danger" v-if="form.rezervasyon_toplam.sepet_indirim > 0">
        <td class="text-right font-weight-bold h6 border-0 p-3">Sepet İndirim :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">{{ form.rezervasyon_toplam.sepet_indirim | cur }} {{ form.sabit_kur }}</td>
      </tr>
      <tr class="text-right" v-if="form.rezervasyon_toplam.iskonto_indirim > 0">
        <td class="text-right font-weight-bold h6 border-0 p-3">Özel İskonto Toplam :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">
          {{ form.rezervasyon_toplam.iskonto_indirim | cur }} {{ form.sabit_kur }}
        </td>
      </tr>
      <tr class="text-right text-primary" v-if="form.kdv_turu == 'haric'">
        <td class="text-right font-weight-bold h6 border-0 p-3">Ara Toplam :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">{{ form.rezervasyon_toplam.ara_toplam | cur }} {{ form.sabit_kur }}</td>
      </tr>
      <tr class="text-right" v-if="form.kdv_turu == 'haric'">
        <td class="text-right font-weight-bold h6 border-0 p-3">Kdv Toplam ( % {{ form.kdv_oran }} ) :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">{{ form.rezervasyon_toplam.kdv_toplam | cur }} {{ form.sabit_kur }}</td>
      </tr>
      <tr class="text-right text-primary" v-if="form.rezervasyon_toplam.ara_toplam > 0">
        <td class="text-right font-weight-bold h6 border-0 p-3">Genel Toplam :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">{{ form.rezervasyon_toplam.genel_toplam | cur }} {{ form.sabit_kur }}</td>
      </tr>
      <!-- <tr class="text-right" v-if="form.rezervasyon_toplam.on_odeme_toplam > 0">
        <td class="text-right font-weight-bold h6 border-0 p-3">Ön Ödeme Tutarı ( % {{ form.rezervasyon_toplam.on_odeme_oran }} ) :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">
          {{ form.rezervasyon_toplam.on_odeme_toplam | cur }} {{ form.sabit_kur }}
        </td>
      </tr> -->
      <tr class="text-right">
        <td class="text-right font-weight-bold h6 border-0 p-3">Tahsilat:</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">
          {{ form.tahsilat | exc(form.secilen_kur, form.sabit_kur) }} {{ form.sabit_kur }}
        </td>
      </tr>
      <tr class="text-right">
        <td class="text-right font-weight-bold h5 border-0 text-primary p-3">KALAN TUTAR :</td>
        <td class="text-right font-weight-bold h5 border-0 pr-1 text-primary">
          {{ kalanBakiye(form.tahsilat) | exc(form.secilen_kur, form.sabit_kur) }} {{ form.sabit_kur }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { computed, defineComponent, toRefs } from '@vue/composition-api';
import { sabitKuraCevir } from '@/libs/global';
export default defineComponent({
  props: {
    form: { type: Object, required: true },
  },
  setup(props) {
    const expo = {};

    const { form } = toRefs(props);

    expo.tahsilEdilen = computed(() => {
      let toplam = 0;
      const tahsilat = form.value.tahsilat;
      if (tahsilat) {
        tahsilat.forEach((el) => {
          if (el.gc_kodu == 'G') {
            toplam += parseFloat(el.tutar);
          } else {
            toplam -= Math.abs(parseFloat(el.tutar));
          }
        });
      }

      if (form.value.sabit_kur == form.value.secilen_kur) {
        return toplam;
      } else {
        return sabitKuraCevir(toplam, form.value.sabit_kur, form.value.secilen_kur);
      }
    });

    expo.kalanBakiye = computed(() => {
      return (tahsil) => {
        let toplam = 0;
        let genel_toplam = form.value.rezervasyon_toplam.genel_toplam;

        toplam = genel_toplam - tahsil;

        return toplam;
      };
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
