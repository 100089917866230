<template>
  <b-sidebar
    backdrop-variant="primary"
    backdrop
    shadow
    :visible="openModal"
    right
    width="25%"
    @change="(val) => $emit('closeModal', val)"
  >
    <template #header="{ hide }">
      <div class="sidebar-sepet-header">
        <h6 class="mb-0 font-weight-bold"><i class="fad fa-sms"></i> E Posta Gönder</h6>
        <i class="fad fa-times-square" @click="hide" />
      </div>
    </template>
    <template #default>
      <b-overlay :show="show" rounded="sm">
        <div class="sidebar-sepet-items px-2">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
              <b-row>
                <b-col cols="12 pt-3">
                  <validation-provider name="Telefon" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Eposta Gönderilecek Mailler">
                      <b-form-input
                        size="lg"
                        class="rounded-0"
                        ref="telefon"
                        v-model="form.e_mail"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12 pt-3">
                  <b-form-group label="Eposta cc" description="Bildirim maili gönderilecek kişileri ekleyebilirsiniz.">
                    <b-form-tags
                      size="lg"
                      class="rounded-0"
                      ref="telefon"
                      placeholder="Cc Eposta"
                      v-model="form.e_mail_cc"
                      aria-describedby="input-1-live-feedback"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12 pt-3">
                  <b-form-group label="Özel Not" description="Voucher için özel not yazabilirsiniz">
                    <b-form-textarea
                      class="rounded-0"
                      rows="4"
                      v-model="form.ozel_not"
                      aria-describedby="input-1-live-feedback"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button size="lg" squared variant="primary" block type="submit">
                    <i class="fad fa-sms" /> EPOSTA GÖNDER
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import store from '@/store';
import { defineComponent, onMounted, reactive, ref, toRef, toRefs, watch } from '@vue/composition-api';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
  components: { vSelect },
  props: {
    openModal: [Boolean],
    rezervasyon: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    const { rezervasyon } = toRefs(props);

    expo.form = ref({
      rezervasyon: {},
      e_mail: null,
      e_mail_cc: [],
      ozel_not: null,
    });

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      expo.show.value = true;

      store.dispatch('rezervasyonMailGonder', expo.form.value).then((res) => {
        if (res.data.success) {
          toast.success('E posta gönderildi.', { position: 'bottom-left' });
          expo.show.value = false;
        } else {
          expo.show.value = false;
        }
      });
    };

    watch(
      rezervasyon,
      (val) => {
        expo.form.value.rezervasyon = val;
        expo.form.value.e_mail = val.misafir.e_mail;
      },
      { deep: true, immediate: true }
    );
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.sidebar-sepet-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  margin-bottom: 30px;
  // p-4 d-flex align-items-center justify-content-between border border-bottom
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}
.sidebar-sepet-items {
  position: relative;
  padding-top: 50px;
}
.sidebar-sepet-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: #fff;
  z-index: 10;
}
</style>
