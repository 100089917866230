<template>
  <b-sidebar
    backdrop-variant="primary"
    backdrop
    shadow
    :visible="openModal"
    right
    width="40%"
    @change="(val) => $emit('closeModal', val)"
  >
    <template #header="{ hide }">
      <div class="sidebar-sepet-header">
        <h6 class="mb-0 font-weight-bold"><i class="fad fa-history"></i> İşlem Geçmişi</h6>
        <i class="fad fa-times-square" @click="hide" />
      </div>
    </template>
    <template #default>
      <div class="sidebar-sepet-items">
        <b-alert show variant="info" class="text-center m-2" v-if="!gecmis.length">
          <h5>Bilgi</h5>
          <hr />
          <p>Şuna her hangi bir işlem bulunmamaktadır.</p>
        </b-alert>

        <ul class="timeline mt-4" v-else>
          <li v-for="(item, i) in gecmis" :key="i" class="event">
            <h4>{{ item.tarih | momentFull }}</h4>
            <h3>{{ item.user }}</h3>
            <p>{{ item.aciklama }}</p>
          </li>
        </ul>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    openModal: [Boolean],
    gecmis: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const expo = {};

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.sidebar-sepet-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  margin-bottom: 30px;
  // p-4 d-flex align-items-center justify-content-between border border-bottom
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}
.sidebar-sepet-items {
  position: relative;
  padding-top: 50px;
}
.sidebar-sepet-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: #fff;
  z-index: 10;
}

.timeline {
  border-left: 3px solid #e1e1e1;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: rgba(174, 174, 174, 0.09);
  margin: 0 auto;
  letter-spacing: 0.2px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 10px 0px 10px 25px;
  list-style: none;
  text-align: left;
  max-width: 90%;
  @media (max-width: 767px) {
    max-width: 98%;
    padding: 25px;
  }
  h1 {
    font-weight: 300;
    font-size: 1.4em;
  }
  h3 {
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
  h4 {
    font-weight: 400;
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
  .event {
    border-bottom: 3px dashed #0a7ced;
    padding-bottom: 10px;
    margin-bottom: 25px;
    position: relative;
    @media (max-width: 767px) {
      padding-top: 30px;
    }
    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }
    &:after,
    :before {
      position: absolute;
      display: block;
      top: 0;
    }
    &:before {
      left: -207px;
      // content: attr(data-date);
      text-align: right;
      font-weight: 100;
      font-size: 0.9em;
      min-width: 120px;
      @media (max-width: 767px) {
        left: 0px;
        text-align: left;
      }
    }
    &:after {
      -webkit-box-shadow: 0 0 0 3px #0a7ced;
      box-shadow: 0 0 0 3px #0a7ced;
      left: -37px;
      background: #fff;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      content: '';
      top: 20px;
      @media (max-width: 767px) {
        left: -31.8px;
      }
    }
  }
}
</style>
