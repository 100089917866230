<template>
  <b-card no-body class="rounded-0 h-100">
    <b-card-header class="bg-white font-weight-bold">Rezervasyon Hızlı İşlemler</b-card-header>
    <b-card-text class="p-2">
      <b-row>
        <b-col cols="12" v-if="rezervasyonDurumlari">
          <b-form-group label-cols="5" label="Rezervasyon Durumu">
            <v-select
              v-model="form.rezervasyon_durum"
              :options="rezervasyonDurumlari"
              :reduce="(rez) => rez.k_no"
              label="baslik"
              :clearable="false"
              class="invoice-filter-select d-block select-size-lg"
              style="width: 100%"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.icerik[defaultDil].baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.icerik[defaultDil].baslik }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label-cols="5" label="Rezervasyon Kapama">
            <v-select
              v-model="form.rezervasyon_kapama"
              :options="rezervasyonKapamaDurum"
              :reduce="(kapama) => kapama.value"
              label="title"
              :clearable="false"
              class="invoice-filter-select d-block select-size-lg"
              style="width: 100%"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="form.rezervasyon_kapama == 'iptal'">
          <b-form-group label-cols="5" label="Rezervasyon İptal Notu">
            <b-form-textarea rows="2" class="rounded-0" v-model="form.rezervasyon_iptal_not" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="text-right">
            <b-button-group>
              <b-button squared variant="success" class="ml-2" @click="handlerGuncelle">
                <i class="fad fa-pencil" /> Kaydet
              </b-button>
              <b-button
                squared
                variant="info"
                class="ml-1"
                v-b-tooltip.hover="'Güncelle'"
                @click="handlerRezervasyonGuncelle(form)"
              >
                <i class="fad fa-pencil" />
              </b-button>
              <b-button
                squared
                variant="warning"
                class="ml-2"
                tag="a"
                :to="{ name: 'rezervasyon-fisi', params: { k_no: form.k_no } }"
                target="_blank"
                v-b-tooltip.hover="'Yazdır'"
              >
                <i class="fad fa-print" />
              </b-button>

              <b-button
                squared
                variant="warning"
                class="ml-1"
                v-b-tooltip.hover="'E Posta Gönder'"
                @click="openEpostaModal = true"
              >
                <i class="fad fa-mail-bulk" />
              </b-button>
              <b-button squared variant="warning" class="ml-1" @click="openSmsModal = true" v-b-tooltip.hover="'Sms Gönder'">
                <i class="fad fa-sms" />
              </b-button>

              <b-button
                squared
                variant="success"
                class="ml-1"
                @click="handlerWhatsappBiletGonder()"
                v-b-tooltip.hover="'Whatsapp Bilet Gönder'"
              >
                <i class="fa fa-whatsapp" />
              </b-button>

              <b-button squared variant="secondary" class="ml-1" v-b-tooltip.hover="'İşlem Geçmişi'" @click="openModal = true">
                <i class="fad fa-history" />
              </b-button>
            </b-button-group>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="text-right">
            <b-button-group>
              <b-button squared variant="danger" @click="handlerOdemeIslemleri">
                <i class="fad fa-sack-dollar" /> Finans İşlemi
              </b-button>
              <b-button
                squared
                variant="outline-danger"
                v-b-tooltip.hover="'Finans Geçmişi'"
                @click="openFinansGecmisModal = true"
              >
                <i class="fad fa-history" />
              </b-button>
              <b-button v-if="form.satis_yontemi == 'sorgula'" squared variant="outline-info" @click="handlerTamamlamaMailGonder">
                Tamamlama Maili Gönder
              </b-button>
            </b-button-group>
          </div>
        </b-col>
      </b-row>
    </b-card-text>
    <RezervasyonIslemGecmisi :gecmis="form.islem_gecmisi" :openModal="openModal" @closeModal="openModal = $event" />
    <RezervasyonEpostaGonder :rezervasyon="form" :openModal="openEpostaModal" @closeModal="openEpostaModal = $event" />
    <RezervasyonSmsGonder :rezervasyon="form" :openModal="openSmsModal" @closeModal="openSmsModal = $event" />
    <RezervasyonFinansIslemleri :rezervasyon="form" :openModal="openFinansModal" @closeModal="openFinansModal = $event" />
    <RezervasyonFinansGecmisi
      :rezervasyon="form"
      :tahsilat="form.tahsilat_detay"
      :openModal="openFinansGecmisModal"
      @closeModal="openFinansGecmisModal = $event"
    />
  </b-card>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification/composition';
import { ref, defineComponent, onMounted, toRefs } from '@vue/composition-api';
import RezervasyonIslemGecmisi from './RezervasyonIslemGecmisi.vue';
import RezervasyonEpostaGonder from './RezervasyonEpostaGonder.vue';
import RezervasyonSmsGonder from './RezervasyonSmsGonder.vue';
import RezervasyonFinansIslemleri from './RezervasyonFinansIslemleri.vue';
import RezervasyonFinansGecmisi from './RezervasyonFinansGecmisi.vue';

import { useRouter } from '@/libs/utils';
import Swal from 'sweetalert2';
import axiosIns from '@/libs/axios';
export default defineComponent({
  components: {
    vSelect,
    RezervasyonIslemGecmisi,
    RezervasyonEpostaGonder,
    RezervasyonSmsGonder,
    RezervasyonFinansIslemleri,
    RezervasyonFinansGecmisi,
  },
  props: {
    form: { type: Object, required: true },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();

    const { form } = toRefs(props);
    const { route, router } = useRouter();
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.openModal = ref(false);
    expo.openSmsModal = ref(false);
    expo.openEpostaModal = ref(false);
    expo.openFinansModal = ref(false);
    expo.openFinansGecmisModal = ref(false);
    expo.openEpostaModal = ref(false);
    expo.rezervasyonDurumlari = ref(null);
    expo.tahsilatDurumlari = ref([
      {
        value: 0,
        title: 'Tahsil Edilmedi',
      },
      {
        value: 1,
        title: 'Ön Ödeme Tahsil Edildi',
      },
      {
        value: 2,
        title: 'Tamamı Tahsil Edildi',
      },
      {
        value: 3,
        title: 'Kısmı Tahsil Edildi',
      },
      {
        value: 4,
        title: 'Beklemede',
      },
    ]);

    expo.rezervasyonKapamaDurum = ref([
      {
        value: 'aktif',
        title: 'Rezervasyon Aktif',
      },
      {
        value: 'kapandi',
        title: 'Rezervasyon Kapandı',
      },
      {
        value: 'iptal',
        title: 'Rezervasyon İptal Edildi',
      },
    ]);

    onMounted(async () => {
      await store.dispatch('rezervasyonDurumListele', { statu: true });
      expo.rezervasyonDurumlari.value = store.getters.getRezervasyonDurumlari;
    });

    expo.handlerOdemeIslemleri = () => {
      expo.openFinansModal.value = true;
    };

    expo.handlerGuncelle = () => {
      context.emit('show', true);
      store.dispatch('rezervasyonGuncelle', form.value).then((res) => {
        if (res.data.success) {
          form.value.islem_gecmisi = res.data.data.islem_gecmisi;
          toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
          context.emit('show', false);
        }
      });
    };

    expo.handlerSiparisMailGonder = () => {
      var form = document.createElement('div');
      form.innerHTML = `
          <input name="tfHours" /><br>
          
          <input name="tfMinutes"/><br>
          `;

      Swal.fire({
        title: 'İnfo',
        text: 'Misafire E posta göndermek üzeresiniz!',
        icon: 'info',
        content: form,
        buttons: {
          cancel: 'Cancel',
          catch: {
            text: 'Create',
            value: 5,
          },
        },
        // showCancelButton: true,
        // confirmButtonText: 'Evet',
        // cancelButtonText: 'Hayır',
        // input: 'textarea',
        // inputLabel: 'Özel Not da yazabilirisiniz',
        // inputPlaceholder: 'Misafire iletmek istedğiniz özel notunuz varsa yazabilirsiniz...',
        // inputAttributes: {
        //   'aria-label': 'Misafire iletmek istedğiniz özel notunuz varsa yazabilirsiniz...',
        // },
      }).then((result) => {
        // if (result.isConfirmed == true) {
        //
        // } else {
        //   store.dispatch('rezervasyonMailGonder', form.value).then((res) => {
        //     if (res.data.success) {
        //       toast.success('E posta gönderildi.', { position: 'bottom-left' });
        //       context.emit('show', false);
        //     } else {
        //       context.emit('show', false);
        //     }
        //   });
        // }
      });
    };

    expo.handlerTamamlamaMailGonder = () => {
      Swal.fire({
        title: 'İnfo',
        text: 'Misafire Rezervasyon Tamamlama E posta göndermek üzeresiniz!',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
        input: 'textarea',
        inputLabel: 'Özel Not da yazabilirisiniz',
        inputPlaceholder: 'Misafire iletmek istedğiniz özel notunuz varsa yazabilirsiniz...',
        inputAttributes: {
          'aria-label': 'Misafire iletmek istedğiniz özel notunuz varsa yazabilirsiniz...',
        },
      }).then((result) => {
        if (result.isConfirmed == true) {
          context.emit('show', true);
          form.value.mail_gonder = true;
          form.value.ozel_not = result.value;
          store.dispatch('rezervasyonTamamlamaMailGonder', form.value).then((res) => {
            if (res.data.success) {
              toast.success('E posta gönderildi.', { position: 'bottom-left' });
              context.emit('show', false);
            } else {
              context.emit('show', false);
            }
          });
        } else {
          form.value.mail_gonder = false;
          form.value.ozel_not = null;
          store.dispatch('rezervasyonTamamlamaMailGonder', form.value).then((res) => {
            if (res.data.success) {
              toast.success('E posta gönderildi.', { position: 'bottom-left' });
              context.emit('show', false);
            } else {
              context.emit('show', false);
            }
          });
        }
      });
    };

    expo.handlerRezervasyonGuncelle = (item) => {
      // router.push({})
      store.commit('SET_DUZENLE_REZERVASYON', item);
      router.push({ name: 'rezervasyon-guncelle', params: { k_no: item.k_no } });
    };

    expo.handlerWhatsappBiletGonder = () => {
      store.dispatch('rezervasyonWhatsappBiletGonder', form.value).then((res) => {});
    };
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
