<template>
  <tr v-if="urun.check">
    <td width="350px">
      <div>
        <small class="d-block font-weight-light"> Ürün Kodu :{{ urun.k_no }} </small>
        <h6 class="font-weight-bold">Hizmet Adı : {{ urun.icerik[defaultDil].baslik }}</h6>
      </div>
    </td>
    <td style="vertical-align: middle" class="text-right">
      <font v-if="urun.hizmet_tipi != 'adet'">
        <b v-for="(gun, index) in urun.gunler.split(',')" :key="index">{{ gun | moment }} - </b>
      </font>
      <font v-else> {{ urun.adet }} Adet </font>
    </td>
    <td style="vertical-align: middle" class="text-right">
      <b v-if="sabitKuraCevir">{{ urun.toplam_fiyat | cur }} {{ form.sabit_kur }}</b>
      <b v-else>{{ urun.kur_toplam_fiyat | cur }} {{ form.secilen_kur }}</b>
    </td>
  </tr>
</template>

<script>
import store from '@/store';
import { ref, computed, defineComponent, toRefs } from '@vue/composition-api';

export default defineComponent({
  props: {
    form: { type: Object, required: true },
    urun: { type: Object, required: true },
    noimg: [Boolean],
    sabitKuraCevir: [Boolean],
  },
  setup(props) {
    const expo = {};
    const { urun } = toRefs(props);

    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.changeCount = (event) => {
      if (event) {
        urun.value.miktar++;
      } else {
        if (urun.value.miktar > 1) urun.value.miktar--;
      }
    };

    expo.renkNedir = computed(() => {
      return (val) => {
        return urun.value.renkler.find((x) => x.k_no == val);
      };
    });

    // expo.toplamHesapla = computed(() => {
    //   if (urun.value.kdv_turu == 'haric') {
    //     const fiyat = Number(urun.value.satis_fiyat * urun.value.miktar);
    //     const kdv = Number(urun.value.kdv_oran);
    //     const total = (fiyat * kdv) / 100;

    //     return fiyat + total;
    //   } else {
    //     return urun.value.satis_fiyat * urun.value.miktar;
    //   }
    // });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
