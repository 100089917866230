<template>
  <b-card no-body class="rounded-0">
    <b-card-header class="bg-white font-weight-bold">Rezervasyon Bilgileri</b-card-header>
    <b-card-text class="p-2">
      <b-list-group flush>
        <b-list-group-item class="border border-top-0 py-2 d-flex justify-content-between align-items-center">
          Rezervasyon İşlem Tarihi :
          <b-badge class="rounded-0 p-2" style="font-size: 12px">{{ form.islem_tarihi | momentFull }}</b-badge>
        </b-list-group-item>
        <b-list-group-item class="border border-top-0 py-2 d-flex justify-content-between align-items-center">
          Rezervasyon No :
          <b-badge class="rounded-0 p-2" style="font-size: 12px">{{ form.k_no }}</b-badge>
        </b-list-group-item>
        <b-list-group-item class="border border-top-0 py-2 d-flex justify-content-between align-items-center">
          Acente & Temsilci :
          <b-badge class="rounded-0 p-2" style="font-size: 12px" v-if="form.ac_k_no"
            >{{ form.acente_firma_adi }} - {{ form.acente_temsilci }}
          </b-badge>
          <b-badge class="rounded-0 p-2" style="font-size: 12px" v-else> Web Sitesinden Gelen Rezervasyon</b-badge>
        </b-list-group-item>
        <b-list-group-item class="border border-top-0 py-2 d-flex justify-content-between align-items-center">
          Misafir :
          <b-badge class="rounded-0 p-2" style="font-size: 12px">
            {{ form.misafir.ad }} {{ form.misafir.soyad }}  | {{ form.misafir.telefon }} | {{ form.misafir.e_mail }}
          </b-badge>
        </b-list-group-item>
        <b-list-group-item class="border border-top-0 py-2 d-flex justify-content-between align-items-center">
          Ödeme Yöntemi :
          <b-badge class="rounded-0 p-2" style="font-size: 12px"> {{ form.odeme_yontemi | odemeYontemi }} </b-badge>
        </b-list-group-item>
        <b-list-group-item
          class="border border-top-0 py-2 d-flex justify-content-between align-items-center"
          v-if="form.odeme_yontemi == 'havale_eft' && form.banka"
        >
          Ödeme Yapılan Banka :
          <b-badge class="rounded-0 p-2 text-right" style="font-size: 12px">
            {{ form.banka.banka_adi }} <br />
            {{ form.banka.banka_iban }}
          </b-badge>
        </b-list-group-item>
        <b-list-group-item class="border border-top-0 py-2 d-flex justify-content-between align-items-center">
          Ödeme Tipi :
          <b-badge class="rounded-0 p-2" style="font-size: 12px"> {{ form.odeme_tipi | odemeTipi }} </b-badge>
        </b-list-group-item>
      </b-list-group>
    </b-card-text>
  </b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    form: { type: Object, required: true },
  },
  setup() {
    const expo = {};

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
