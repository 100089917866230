<template>
  <tr>
    <td colspan="4" class="p-0 m-0">
      <template v-if="urun.urun_tipi == 'villa' || urun.urun_tipi == 'yat'">
        <table class="table table-striped p-0 m-0">
          <tr>
            <td style="vertical-align: middle; width: 500px">
              <table class="w-100 table table-sm table-borderless">
                <tr>
                  <td rowspan="7" class="w-25" style="vertical-align: middle; text-align: center">
                    <b-img thumbnail :src="urun.gorseller | sepetImage(urun.urun_k_no, 'urun')" width="100" v-if="!noimg" />
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <i class="fad fa-box-full" />
                    <strong class="font-weight-bold h6">{{ urun.baslik[defaultDil] }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <i class="fad fa-calendar pr-1"></i>
                    <strong class="font-weight-bold">Ürün Kodu</strong>
                  </td>
                  <td>{{ urun.urun_kodu }}</td>
                </tr>
                <tr>
                  <td>
                    <i class="fad fa-ticket pr-1"></i>
                    <strong class="font-weight-bold">Bilet No</strong>
                  </td>
                  <td>{{ urun.bilet_no }}</td>
                </tr>
              </table>
            </td>
            <td style="vertical-align: middle; width: 370px">
              <table class="w-100 table table-sm table-borderless">
                <tr>
                  <td><i class="fad fa-calendar pr-1"></i> <strong class="font-weight-bold">Giriş Tarihi</strong></td>
                  <td>{{ urun.rezervasyon_tarihi.giris | moment }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-calendar pr-1"></i> <strong class="font-weight-bold">Çıkış Tarihi</strong></td>
                  <td>{{ urun.rezervasyon_tarihi.cikis | moment }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-calendar pr-1"></i> <strong class="font-weight-bold">Konaklama Süresi</strong></td>
                  <td>{{ urun.toplam_gun }} Gün</td>
                </tr>
              </table>
            </td>

            <td style="vertical-align: middle; width: 300px">
              <table class="w-100 table table-sm table-borderless">
                <tr>
                  <td><i class="fad fa-user"></i> Yetişkin</td>
                  <td>{{ urun.pax.yetiskin }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-child"></i> Çocuk</td>
                  <td>{{ urun.pax.cocuk }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-baby"></i> Bebek</td>
                  <td>{{ urun.pax.bebek }}</td>
                </tr>
              </table>
            </td>
            <td align="right" style="vertical-align: middle" class="h5" v-if="fiyat == 'fiyatli'">
              <b>{{ urun.sabit_toplam.toplam | cur }} {{ form.sabit_kur }}</b>
              <b>{{ urun.sabit_toplam.indirim_toplam | cur }} {{ form.sabit_kur }}</b>
            </td>
          </tr>

          <tr class="border-bottom" v-if="urun.ekstra_hizmetler.length > 0">
            <td colspan="5" class="p-1">
              <table class="table table-sm table-bordered p-0 m-0 table-secondary">
                <tr>
                  <th colspan="4" class="h6 font-weight-bold">( {{ urun.baslik[defaultDil] }} ) Diger Misafir Bilgileri</th>
                </tr>
                <UrunDigerMisafirItem v-for="(misafir, index) in form.diger_misafir" :key="index" :index="index" :misafir="misafir" />
              </table>
            </td>
          </tr>
          <tr class="border-bottom" v-if="urun.ekstra_hizmetler.length > 0">
            <td colspan="5" class="p-1">
              <table class="table table-sm table-bordered p-0 m-0 table-secondary">
                <tr>
                  <th colspan="4" class="h6 font-weight-bold">( {{ urun.baslik[defaultDil] }} ) Ürüne Ait Ekstra Ürün Bilgisi</th>
                </tr>
                <UrunEkstraHizmetItem
                  v-for="(hizmet, index) in urun.ekstra_hizmetler"
                  :key="index"
                  :index="index"
                  :hizmet="hizmet"
                  :urun="urun"
                  :sabitKuraCevir="sabitKuraCevir"
                  :form="form"
                  :fiyat="fiyat"
                />
              </table>
            </td>
          </tr>
        </table>
      </template>
      <template v-else-if="urun.urun_tipi == 'tur'">
        <table class="table table-striped p-0 m-0">
          <tr>
            <td style="vertical-align: middle; width: 500px">
              <table class="w-100 table table-sm table-borderless">
                <tr>
                  <td rowspan="7" class="w-25" style="vertical-align: middle; text-align: center">
                    <b-img
                      thumbnail
                      :src="urun.gorseller | sepetImage(urun.urun_k_no, 'urun')"
                      width="100"
                      v-if="!noimg && urun.urun_tipi != 'transfer'"
                    />
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <i class="fad fa-box-full" /> <strong class="font-weight-bold h6">{{ urun.baslik[defaultDil] }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <i class="fad fa-ticket pr-1"></i>
                    <strong class="font-weight-bold">Bilet No</strong>
                  </td>
                  <td>{{ urun.bilet_no }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-stream pr-1"></i> <strong class="font-weight-bold">Ürün Kodu</strong></td>
                  <td>{{ urun.urun_kodu }}</td>
                </tr>
                <tr v-if="urun.periyot_fiyat && Object.values(urun.periyot_fiyat).length">
                  <td>
                    <i class="fad fa-ticket pr-1"></i>
                    <strong class="font-weight-bold">Program</strong>
                  </td>
                  <td>{{ urun.periyot_fiyat.baslik[defaultDil] }}</td>
                </tr>
              </table>
            </td>
            <td style="vertical-align: middle; width: 370px">
              <table class="w-100 table table-sm table-borderless">
                <tr>
                  <td><i class="fad fa-calendar pr-1"></i> <strong class="font-weight-bold">Tarih</strong></td>
                  <td>{{ urun.rezervasyon_tarihi.giris | moment }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-clock pr-1"></i> <strong class="font-weight-bold">Seans Saati</strong></td>
                  <td>{{ urun.seans_saati }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-clock pr-1"></i> <strong class="font-weight-bold">Bölge Alış Saati</strong></td>
                  <td>{{ urun.bolge_alis_saati }}</td>
                </tr>
              </table>
            </td>

            <td style="vertical-align: middle; width: 300px">
              <table class="w-100 table table-sm table-borderless">
                <tr>
                  <td><i class="fad fa-user"></i> Yetişkin</td>
                  <td>{{ urun.pax.yetiskin }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-child"></i> Çocuk</td>
                  <td>{{ urun.pax.cocuk }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-baby"></i> Bebek</td>
                  <td>{{ urun.pax.bebek }}</td>
                </tr>
              </table>
            </td>
            <td align="right" style="vertical-align: middle" class="h5" v-if="fiyat == 'fiyatli'">
              <b :style="urun.sabit_toplam.indirim_toplam > 0 && 'text-decoration:line-through; color:red'"
                >{{ urun.sabit_toplam.toplam | cur }} {{ form.sabit_kur }}</b
              >
              <br />
              <b v-if="urun.sabit_toplam.indirim_toplam > 0" :class="urun.sabit_toplam.indirim_toplam > 0 && 'text-info'">
                {{ urun.sabit_toplam.indirim_toplam | cur }} {{ form.sabit_kur }}</b
              >
            </td>
          </tr>
          <tr class="border-bottom" v-if="urun.ekstra_hizmetler.length > 0">
            <td colspan="5" class="p-1">
              <table class="table table-sm table-bordered p-0 m-0 table-secondary">
                <tr>
                  <th colspan="4" class="h6 font-weight-bold">( {{ urun.baslik[defaultDil] }} ) Ürüne Ait Ekstra Ürün Bilgisi</th>
                </tr>
                <UrunEkstraHizmetItem
                  v-for="(hizmet, index) in urun.ekstra_hizmetler"
                  :key="index"
                  :index="index"
                  :hizmet="hizmet"
                  :urun="urun"
                  :sabitKuraCevir="sabitKuraCevir"
                  :form="form"
                  :fiyat="fiyat"
                />
              </table>
            </td>
          </tr>
        </table>
      </template>
      <template v-else-if="urun.urun_tipi == 'transfer'">
        <table class="table table-striped p-0 m-0">
          <tr>
            <td style="vertical-align: middle; width: 500px">
              <table class="w-100 table table-sm table-borderless">
                <tr>
                  <td rowspan="7" class="w-25" style="vertical-align: middle; text-align: center">
                    <b-img thumbnail :src="urun.gorseller | sepetImage(urun.urun_k_no)" width="100" v-if="!noimg" />
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <i class="fad fa-box-full" />
                    <strong class="font-weight-bold h6"
                      >{{ urun.baslik[defaultDil] }} ( {{ urun.kapasite.min }} / {{ urun.kapasite.max }}) Kişi</strong
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <i class="fad fa-ticket pr-1"></i>
                    <strong class="font-weight-bold">Bilet No</strong>
                  </td>
                  <td>{{ urun.bilet_no }}</td>
                </tr>
                <tr>
                  <td>
                    <i class="fad fa-clock pr-1"></i>
                    <strong class="font-weight-bold"> {{ urun.saat_hesapla ? 'Uçuş Saati' : 'İniş Saati' }}</strong>
                  </td>
                  <td>{{ urun.ucus_saat }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-ticket-alt pr-1"></i> <strong class="font-weight-bold">Uçuş No</strong></td>
                  <td>{{ urun.ucus_no }}</td>
                </tr>
              </table>
            </td>
            <td style="vertical-align: middle; width: 370px">
              <table class="w-100 table table-sm table-borderless">
                <tr>
                  <td>
                    <i class="fad fa-calendar pr-1"></i>
                    <strong class="font-weight-bold"> Tarih</strong>
                  </td>
                  <td>{{ urun.rezervasyon_tarihi.giris | momentFull }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-calendar pr-1"></i> <strong class="font-weight-bold">Nereden</strong></td>
                  <td>{{ urun.nereden[defaultDil] }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-arrow-down pr-1"></i> <strong class="font-weight-bold">Nereye</strong></td>
                  <td>{{ urun.nereye[defaultDil] }}</td>
                </tr>
              </table>
            </td>

            <td style="vertical-align: middle; width: 300px">
              <table class="w-100 table table-sm table-borderless">
                <tr>
                  <td><i class="fad fa-user"></i> Yetişkin</td>
                  <td>{{ urun.pax.yetiskin }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-child"></i> Çocuk</td>
                  <td>{{ urun.pax.cocuk }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-baby"></i> Bebek</td>
                  <td>{{ urun.pax.bebek }}</td>
                </tr>
              </table>
            </td>
            <td align="right" style="vertical-align: middle" class="h5" v-if="fiyat == 'fiyatli'">
              <b :style="urun.sabit_toplam.indirim_toplam > 0 && 'text-decoration:line-through; color:red'">
                {{ urun.sabit_toplam.toplam | cur }} {{ form.sabit_kur }}
              </b>
              <br />
              <b v-if="urun.sabit_toplam.indirim_toplam > 0" :class="urun.sabit_toplam.indirim_toplam > 0 && 'text-info'">
                {{ urun.sabit_toplam.indirim_toplam | cur }} {{ form.sabit_kur }}
              </b>
            </td>
          </tr>
          <tr class="border-bottom" v-if="urun.ekstra_hizmetler.length > 0">
            <td colspan="5" class="p-1">
              <table class="table table-sm table-bordered p-0 m-0 table-secondary">
                <tr>
                  <th colspan="4" class="h6 font-weight-bold">
                    ( {{ urun.baslik[defaultDil] }} - {{ urun.kapasite.min }} / {{ urun.kapasite.max }} ) Ürüne Ait Ekstra Hizmetler
                  </th>
                </tr>
                <UrunEkstraHizmetItem
                  v-for="(hizmet, index) in urun.ekstra_hizmetler"
                  :key="index"
                  :index="index"
                  :hizmet="hizmet"
                  :urun="urun"
                  :sabitKuraCevir="sabitKuraCevir"
                  :form="form"
                  :fiyat="fiyat"
                />
              </table>
            </td>
          </tr>
        </table>
      </template>
      <template v-else-if="urun.urun_tipi == 'arac_kira'">
        <table class="table table-striped p-0 m-0">
          <tr>
            <td style="vertical-align: middle; width: 500px">
              <table class="w-100 table table-sm table-borderless">
                <tr>
                  <td rowspan="7" class="w-25" style="vertical-align: middle; text-align: center">
                    <b-img thumbnail :src="urun.gorseller | sepetImage(urun.urun_k_no, 'urun')" width="100" v-if="!noimg" />
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <i class="fad fa-box-full" />
                    <strong class="font-weight-bold h6">{{ urun.baslik[defaultDil] }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <i class="fad fa-ticket pr-1"></i>
                    <strong class="font-weight-bold">Bilet No</strong>
                  </td>
                  <td>{{ urun.bilet_no }}</td>
                </tr>
                <tr>
                  <td>
                    <i class="fad fa-calendar pr-1"></i>
                    <strong class="font-weight-bold">Toplam Gün</strong>
                  </td>
                  <td>{{ urun.toplam_gun }} Gün</td>
                </tr>
              </table>
            </td>
            <td style="vertical-align: middle; width: 370px">
              <table class="w-100 table table-sm table-borderless">
                <tr>
                  <td><i class="fad fa-arrow-up pr-1"></i> <strong class="font-weight-bold">Alış Bölgesi</strong></td>
                  <td>{{ urun.alis_bolgesi[defaultDil] }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-arrow-down pr-1"></i> <strong class="font-weight-bold">Teslim Bölgesi</strong></td>
                  <td>{{ urun.teslim_bolgesi[defaultDil] }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-calendar pr-1"></i> <strong class="font-weight-bold">Alış Tarihi</strong></td>
                  <td>{{ urun.rezervasyon_tarihi.giris | momentFull }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-calendar pr-1"></i> <strong class="font-weight-bold">Teslim Tarihi</strong></td>
                  <td>{{ urun.rezervasyon_tarihi.cikis | momentFull }}</td>
                </tr>
              </table>
            </td>

            <td style="vertical-align: middle; width: 300px">
              <table class="w-100 table table-sm table-borderless">
                <tr>
                  <td><i class="fad fa-user"></i> Yetişkin</td>
                  <td>{{ urun.pax.yetiskin }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-child"></i> Çocuk</td>
                  <td>{{ urun.pax.cocuk }}</td>
                </tr>
                <tr>
                  <td><i class="fad fa-baby"></i> Bebek</td>
                  <td>{{ urun.pax.bebek }}</td>
                </tr>
              </table>
            </td>
            <td align="right" style="vertical-align: middle" class="h5" v-if="fiyat == 'fiyatli'">
              <b :style="urun.sabit_toplam.indirim_toplam > 0 && 'text-decoration:line-through; color:red'">
                {{ urun.sabit_toplam.toplam | cur }} {{ form.sabit_kur }}
              </b>
              <br />
              <b v-if="urun.sabit_toplam.indirim_toplam > 0" :class="urun.sabit_toplam.indirim_toplam > 0 && 'text-info'">
                {{ urun.sabit_toplam.indirim_toplam | cur }} {{ form.sabit_kur }}
              </b>
            </td>
          </tr>
          <tr class="border-bottom" v-if="urun.ekstra_hizmetler.length > 0">
            <td colspan="5" class="p-1">
              <table class="table table-sm table-bordered p-0 m-0 table-secondary">
                <tr>
                  <th colspan="4" class="h6 font-weight-bold">( {{ urun.baslik[defaultDil] }} ) Ürüne Ait Ekstra Ürün Bilgisi</th>
                </tr>
                <UrunEkstraHizmetItem
                  v-for="(hizmet, index) in urun.ekstra_hizmetler"
                  :key="index"
                  :index="index"
                  :hizmet="hizmet"
                  :urun="urun"
                  :sabitKuraCevir="sabitKuraCevir"
                  :form="form"
                  :fiyat="fiyat"
                />
              </table>
            </td>
          </tr>
        </table>
      </template>
    </td>
  </tr>
</template>

<script>
import store from '@/store';
import { ref, computed, defineComponent, toRefs } from '@vue/composition-api';
import UrunDigerMisafirItem from './UrunDigerMisafirItem.vue';
import UrunEkstraHizmetItem from './UrunEkstraHizmetItem.vue';

export default defineComponent({
  components: { UrunEkstraHizmetItem, UrunDigerMisafirItem },
  props: {
    form: { type: Object, required: true },
    urun: { type: Object, required: true },
    noimg: [Boolean],
    sabitKuraCevir: [Boolean],
    fiyat: {
      type: String,
      default: 'fiyatli',
    },
  },
  setup(props) {
    const expo = {};
    const { urun } = toRefs(props);

    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    const bolgeler = ref(store.getters.getTumBolgeler);

    expo.bolgeNedir = computed(() => {
      return (val) => {
        const bolge = bolgeler.value.find((x) => x.k_no == val);
        if (bolge != undefined) {
          return bolge.icerik[expo.defaultDil.value].baslik;
        }
        return '';
      };
    });

    expo.changeCount = (event) => {
      if (event) {
        urun.value.miktar++;
      } else {
        if (urun.value.miktar > 1) urun.value.miktar--;
      }
    };

    expo.renkNedir = computed(() => {
      return (val) => {
        return urun.value.renkler.find((x) => x.k_no == val);
      };
    });

    // expo.toplamHesapla = computed(() => {
    //   if (urun.value.kdv_turu == 'haric') {
    //     const fiyat = Number(urun.value.satis_fiyat * urun.value.miktar);
    //     const kdv = Number(urun.value.kdv_oran);
    //     const total = (fiyat * kdv) / 100;

    //     return fiyat + total;
    //   } else {
    //     return urun.value.satis_fiyat * urun.value.miktar;
    //   }
    // });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
