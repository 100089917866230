<template>
  <div>
    <b-modal
      :visible="openModal"
      @change="(val) => $emit('closeModal', false)"
      class="rounded-0"
      centered
      size="lg"
      title="Tahsilat | Ödeme İşlemi"
    >
      <b-card-text>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Tahsilat Durumu">
              <v-select
                v-model="form.tahsilat_durum"
                :options="tahsilatDurumlari"
                :reduce="(kok) => kok.value"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Tahsilat | Ödeme">
              <v-select
                v-model="form.gc_kodu"
                :options="gcKodu"
                :reduce="(kok) => kok.value"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Tahsilat | Ödeme ( Tipi )">
              <v-select
                v-model="form.tipi"
                :options="tahsilatTipi"
                :reduce="(kok) => kok.value"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="İşlem Tarihi">
              <flat-pickr
                v-model="date"
                class="form-control form-control-lg rounded-0 bg-white"
                :config="config"
                :events="config.events"
                @on-change="handlerOnChangeDate"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Tutar">
              <b-input-group>
                <b-form-input type="number" size="lg" class="rounded-0 text-right" ref="baslik" v-model="form.tutar" />
                <b-input-group-prepend is-text>
                  {{ form.secilen_kur }}
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Açıklama">
              <b-form-textarea size="lg" class="rounded-0" ref="baslik" v-model="form.aciklama" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" type="submit" @click="onSubmit" :disabled="show">
            <i class="fad fa-save" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            {{ form.k_no == null ? 'KAYDET' : 'GÜNCELLE' }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store';
import moment from 'moment';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification/composition';
import { ref, toRefs, watch, computed } from '@vue/composition-api';
import 'flatpickr/dist/flatpickr.css';
import flatPickr from 'vue-flatpickr-component';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
export default {
  components: {
    vSelect,
    flatPickr,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    rezervasyon: {
      type: Object,
      required: false,
    },
    updateData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { rezervasyon, updateData } = toRefs(props);
    expo.kurlar = ref(store.getters.getKurListesi);
    expo.kurTurleri = computed(() => store.getters.getKurAyarlari);
    expo.stokBirimleri = computed(() => store.getters.getStokBirimler);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.show = ref(false);
    expo.gcKodu = ref([
      { value: 'G', title: 'Tahsilat' },
      { value: 'C', title: 'Ödeme' },
    ]);

    expo.tahsilatDurumlari = ref([
      {
        value: 0,
        title: 'Tahsil Edilmedi',
      },
      {
        value: 1,
        title: 'Ön Ödeme Tahsil Edildi',
      },
      {
        value: 2,
        title: 'Tamamı Tahsil Edildi',
      },
      {
        value: 3,
        title: 'Kısmı Tahsil Edildi',
      },
      {
        value: 4,
        title: 'Beklemede',
      },
    ]);

    expo.form = ref({
      k_no: null,
      uye_k_no: rezervasyon.value.uye_k_no,
      rez_k_no: rezervasyon.value.k_no,
      tarih: moment().format('YYYY-MM-DD'),
      tipi: 'nakit', //nakit,kredi_karti
      sabit_kur: rezervasyon.value.sabit_kur,
      sabit_kur_fiyat: rezervasyon.value.sabit_kur_fiyat,
      secilen_kur: rezervasyon.value.secilen_kur,
      secilen_kur_fiyat: rezervasyon.value.secilen_kur_fiyat,
      gc_kodu: 'G',
      tutar: 0,
      aciklama: null,
      tahsilat_durum: null,
    });

    expo.tahsilatTipi = ref([
      {
        value: 'nakit',
        title: 'Nakit',
      },
      {
        value: 'kredi_karti',
        title: 'Kredi Kartı',
      },
      {
        value: 'havale',
        title: 'Havale',
      },
    ]);

    expo.date = ref(moment().format('DD.MM.YYYY'));
    expo.config = ref({
      wrap: true,
      events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
      inline: false,
      mode: 'single',
      minDate: 'today',
      dateFormat: 'd.m.Y',
      locale: Turkish, // locale for this instance only
    });

    expo.handlerOnChangeDate = (event) => {
      if (event) expo.form.value.tarih = moment(String(event)).format('YYYY-MM-DD');
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };
    expo.onSubmit = () => {
      expo.show.value = true;
      const baseUrl = expo.form.value.k_no == null ? 'rezervasyonTahsilatEkle' : 'rezervasyonTahsilatGuncelle';
      store
        .dispatch(baseUrl, expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(expo.form.value.k_no == null ? 'Ekleme Başarılı' : 'Güncelleme Başarılı', { position: 'bottom-left' });
            rezervasyon.value.tahsilat.push(expo.form.value);
            expo.show.value = false;
          } else {
            expo.show.value = false;
            toast.error(res.data.message, { position: 'bottom-left' });
          }
        })
        .catch((err) => {
          console.log(err);
          expo.show.value = false;
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    // watch(
    //   openModal,
    //   (val) => {
    //     if (val == true) {
    //       if (Object.keys(updateData.value).length) {
    //         expo.form.value = { ...updateData.value };
    //       } else {
    //         expo.form.value.urun_k_no = urun.value.k_no;
    //         expo.form.value.sabit_kur = expo.kurTurleri.value.varsayilan;

    //         const birim = expo.stokBirimleri.value.find((x) => x.k_no == urun.value.stok_birim_k_no);
    //         if (birim != undefined) {
    //           expo.stok_birim.value = birim.icerik[expo.defaultDil.value].baslik;
    //         } else {
    //           expo.stok_birim.value = 'Adet';
    //         }
    //       }
    //     }
    //   },
    //   { deep: true }
    // );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
