<template>
  <tr class="w-100" v-if="hizmet.check">
    <td style="vertical-align: middle; width: 30px">{{ index + 1 }}</td>
    <td class="h6 font-weight-bold w-50">{{ hizmet.icerik['tr'].baslik }}</td>
    <td class="h6 font-weight-bold w-25">
      {{ hizmet.hizmet_tipi == 'adet' ? hizmet.adet : hizmet.gunler }} {{ hizmet.hizmet_tipi == 'adet' ? 'Adet' : '' }}
    </td>

    <td class="text-right font-weight-bold h6 text-danger" style="min-width: 90px" v-if="fiyat == 'fiyatli'">
      <template v-if="sabitKuraCevir"> {{ hizmet.toplam_fiyat | cur }} {{ form.sabit_kur }} </template>
      <template v-else> {{ hizmet.kur_toplam_fiyat | cur }} {{ form.secilen_kur }} </template>
    </td>
  </tr>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    index: [Number],
    hizmet: {
      type: Object,
      required: true,
    },
    urun: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    fiyat: {
      type: String,
      default: 'fiyatli',
    },
    sabitKuraCevir: [Boolean],
  },
  setup() {
    const expo = {};

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
