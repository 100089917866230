<template>
  <div v-if="form">
    <b-row>
      <b-col cols="12" md="6">
        <RezervasyonBilgileri :form="form" />
      </b-col>
      <b-col cols="12" md="6">
        <RezervasyonIslemleri :form="form" @show="$emit('show', $event)" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card no-body class="rounded-0">
          <b-card-header class="bg-white font-weight-bold d-flex justify-content-between">
            Rezervasyon Ürünleri
            <b-button squared size="sm" variant="info" v-if="form.sabit_kur != form.secilen_kur" @click="sabitKuraCevir = !sabitKuraCevir">
              Sabit Kura Cevir
            </b-button>
          </b-card-header>
          <b-card-text class="p-2">
            <table class="table border table-borderless table-striped outlined p-0 m-0">
              <thead class="table-warning">
                <tr>
                  <th colspan="3" scope="col" class="text-left font-weight-bold h6">#Ürün</th>
                  <th scope="col" class="text-right font-weight-bold h6" width="15%">Tutar</th>
                </tr>
              </thead>

              <tbody v-if="(form.rezervasyon_toplam && form.sabit_kur == form.secilen_kur) || sabitKuraCevir">
                <template>
                  <UrunSabitKurItem
                    v-for="(urun, i) in form.sepet_urunler"
                    :key="i"
                    :urun="urun"
                    :form="form"
                    :sabitKuraCevir="sabitKuraCevir"
                  />
                </template>
              </tbody>
              <tbody v-else-if="(form.kur_sepet_toplam && form.sabit_kur != form.secilen_kur) || !sabitKuraCevir">
                <template>
                  <UrunSecilenKurItem
                    v-for="(urun, i) in form.sepet_urunler"
                    :key="i"
                    :urun="urun"
                    :form="form"
                    :sabitKuraCevir="sabitKuraCevir"
                  />
                </template>
              </tbody>
            </table>
            <table class="table-bordered w-100">
              <tr>
                <th class="p-2" style="vertical-align: top">
                  <font v-if="form.rezervasyon_notu">
                    <b class="d-block h6 font-weight-bold">Rezervasyon Notu :</b>
                    <p>{{ form.rezervasyon_notu }}</p>
                  </font>
                  <font v-if="form.hotel_bilgi">
                    <b class="d-block h6 font-weight-bold">Hotel Bilgisi</b>
                    <b>Hotel Adı</b> : {{ form.hotel_bilgi.hotel_adi }} <br />
                    <b>Hotel Oda No</b> : {{ form.hotel_bilgi.oda_no }} <br />
                    <b>Hotel Lokasyon</b> : {{ form.hotel_bilgi.adres }} <br />
                  </font>
                </th>
                <th class="p-0 m-0" style="width: 500px">
                  <UrunSabitKurToplam :form="form" v-if="form.sabit_kur == form.secilen_kur || sabitKuraCevir" />
                  <UrunSecilenKurToplam :form="form" v-else />
                </th>
              </tr>
            </table>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import { useRouter } from '@/libs/utils';
import { useToast } from 'vue-toastification/composition';
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import RezervasyonBilgileri from './component/detay/RezervasyonBilgileri.vue';
import RezervasyonIslemleri from './component/detay/RezervasyonIslemleri.vue';
import RezervasyonIslemGecmisi from './component/detay/RezervasyonIslemGecmisi.vue';
import UrunSabitKurItem from './component/detay/UrunSabitKurItem.vue';
import UrunSecilenKurItem from './component/detay/UrunSecilenKurItem.vue';
import UrunEkstraSabitKurItem from './component/detay/UrunEkstraSabitKurItem.vue';
import UrunEkstraSecilenKurItem from './component/detay/UrunEkstraSecilenKurItem.vue';
import UrunSabitKurToplam from './component/detay/UrunSabitKurToplam.vue';
import UrunSecilenKurToplam from './component/detay/UrunSecilenKurToplam.vue';
export default defineComponent({
  components: {
    vSelect,
    RezervasyonBilgileri,
    RezervasyonIslemleri,
    RezervasyonIslemGecmisi,
    UrunSabitKurItem,
    UrunSecilenKurItem,
    UrunEkstraSabitKurItem,
    UrunEkstraSecilenKurItem,
    UrunSabitKurToplam,
    UrunSecilenKurToplam,
  },
  setup(props, context) {
    const expo = {};
    const { route } = useRouter();
    // const toast = useToast();
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.sabitKuraCevir = ref(false);
    expo.form = ref(null);

    // expo.ekstraHizmetVarmi = computed(() => {
    //   return expo.form.value.ekstra_hizmetler.some((x) => x.check == true);
    // });

    onMounted(async () => {
      context.emit('show', true);
      const k_no = route.value.params.k_no;
      context.emit('pageTitle', `${k_no} Nolu Rezervasyon`);
      if (k_no != null) {
        const rezervasyon = store.getters.getRezervasyonlar.find((x) => x.k_no == k_no);
        if (rezervasyon) {
          expo.form.value = rezervasyon;
          context.emit('show', false);
        } else {
          await store.dispatch('rezervasyonFindOne', k_no).then((res) => {
            const rezervasyon = res;
            expo.form.value = rezervasyon;
            context.emit('show', false);
          });
        }
      }
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
