<template>
  <b-sidebar
    backdrop-variant="primary"
    backdrop
    shadow
    :visible="openModal"
    right
    width="80%"
    @change="(val) => $emit('closeModal', val)"
  >
    <template #header="{ hide }">
      <div class="sidebar-sepet-header">
        <h6 class="mb-0 font-weight-bold"><i class="fad fa-history"></i> Finans Hareketleri</h6>
        <i class="fad fa-times-square fa-2x" @click="hide" />
      </div>
    </template>
    <template #default>
      <div class="sidebar-sepet-items">
        <b-alert show variant="info" class="text-center m-2" v-if="!tahsilat.length">
          <h5>Bilgi</h5>
          <hr />
          <p>Şuna her hangi bir işlem bulunmamaktadır.</p>
        </b-alert>
        <div class="p-2" v-else>
          <vue-good-table :columns="columns" :rows="tahsilat" :line-numbers="false" styleClass="vgt-table striped bordered">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'tarih'">
                {{ props.row.tarih | momentFull }}
              </span>
              <span v-else-if="props.column.field === 'tipi'">
                {{ props.row.tipi == 'nakit' ? 'Nakit' : props.row.tipi == 'havale_eft' ? 'Havale EFT' : 'Kredi Kartı' }}
              </span>
              <span v-else-if="props.column.field === 'gc_kodu'">
                {{ props.row.gc_kodu == 'G' ? 'Giriş' : 'Çıkış' }}
              </span>
              <span v-else-if="props.column.field === 'tutar'"> {{ props.row.tutar | cur }} {{ props.row.secilen_kur }} </span>
              <span v-else-if="props.column.field === 'action'">
                <b-button-group>
                  <b-button size="sm" squared variant="danger" @click="handlerRemove(props.row.k_no)">
                    <i class="fad fa-trash"></i
                  ></b-button>
                </b-button-group>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import { ref, defineComponent, toRefs } from '@vue/composition-api';
export default defineComponent({
  components: { VueGoodTable },
  props: {
    openModal: [Boolean],
    tahsilat: {
      type: Array,
      required: true,
    },
    rezervasyon: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expo = {};
    const toast = useToast();
    const { tahsilat, rezervasyon } = toRefs(props);
    expo.columns = ref([
      {
        label: 'İşlem Tarihi',
        field: 'tarih',
        width: '10%',
      },
      {
        label: 'Tipi',
        field: 'tipi',
        width: '15%',
      },
      {
        label: 'G | C',
        field: 'gc_kodu',
        width: '7%',
      },
      {
        label: 'Tutar',
        field: 'tutar',
        tdClass: 'text-right',
        thClass: 'text-right',
        width: '15%',
      },
      {
        label: 'Açıklama',
        field: 'aciklama',
        width: '20%',
      },
      {
        label: 'İşlemler',
        field: 'action',
        thClass: 'text-right',
        tdClass: 'text-right',
        width: '5%',
      },
    ]);

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          const index = tahsilat.value.findIndex((x) => x.k_no == k_no);

          store.dispatch('rezervasyonTahsilatSil', k_no).then((res) => {
            if (res.data.success === true) {
              tahsilat.value.splice(index, 1);
              rezervasyon.value.tahsilat = tahsilat;

              toast.error('Silme Başarılı', { position: 'bottom-left' });
            }
          });
        }
      });
    };
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.sidebar-sepet-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  margin-bottom: 30px;
  // p-4 d-flex align-items-center justify-content-between border border-bottom
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}
.sidebar-sepet-items {
  position: relative;
  padding-top: 50px;
}
.sidebar-sepet-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: #fff;
  z-index: 10;
}
</style>
